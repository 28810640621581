import IImage from '@common/Image.interface'
import Props from '@common/Props.interface'
import ImageSize from '@components/shared/ImageSize'
import classNames from '@utils/classNames'
import resolveImageSize from '@utils/resolveImageSize'
import NextImage from 'next/image'

enum Variant {
  ROUND = 'ROUND',
}

enum Position {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export interface ImageProps extends Props {
  image: IImage
  variant?: Variant
  position: Position
  imageSize?: ImageSize
}

const IMAGE_VARIANT_MAPS: Record<Variant, string> = {
  [Variant.ROUND]: 'rounded-full',
}

const IMAGE_POSITION_MAPS: Record<Position, string> = {
  [Position.LEFT]: 'hidden md:block left-0 right-1/2',
  [Position.RIGHT]: 'hidden md:block left-1/2 right-0',
}

const Image = ({
  className = '',
  variant,
  position,
  image,
  style,
  imageSize = ImageSize.MEDIUM,
}: ImageProps) => {
  const imagePositionClassName = position !== undefined ? IMAGE_POSITION_MAPS[position] : null
  const imageVariantClassName = variant !== undefined ? IMAGE_VARIANT_MAPS[variant] : null

  const sizedImage = resolveImageSize(image, imageSize)

  return (
    <div
      className={classNames('absolute inset-0', imagePositionClassName, className)}
      style={style}
    >
      {sizedImage && (
        <NextImage
          src={sizedImage.url}
          fill
          placeholder="blur"
          blurDataURL={image.blurhash}
          alt={image.alternativeText ?? ''}
          className={classNames('object-contain object-bottom', imageVariantClassName)}
        />
      )}
    </div>
  )
}

Image.position = Position
Image.variant = Variant

export default Image
